<script setup>
import { onMounted, ref, onUnmounted } from "vue";
import StepOone from "./stepOne/index.vue";
import StepTwo from "./stepTwo/index.vue";
import StepThree from "./stepThree/index.vue";
import StepFour from "./stepFour/index.vue";
import Letter from './letter/index.vue';
import {
  temporaryStorage,
  submit,
  getIdentityDuplicationInThreee,
  changeAmountRaiseStatus
} from "@/api/index.js";
import { ElMessage, ElMessageBox } from "element-plus";
import Done from "../../assets/stepFour/img-four.png";
import { useRouter, useRoute } from "vue-router";
import { getEntrepreneurDoorDtoPerson } from "@/api/applyList.js";
import { downloadByFileId } from "@/api/index.js";

const router = useRouter();
const route = useRoute();
const steps = ref(1);
const detailInfo = ref({});
const idNumber = ref(""); // 获取第一个步骤的身份证号码
const reData = ref({}); // 保存暂存之后的返回信息
const visible_priceEdit = ref(false);
const editPriceStatus = ref(false);
const amountRaiseStatus = ref('0'); // 金额提升状态
const showLetter = ref(false);
const submitCount = ref(5);

// 返回首页
const goIndex = () => {
  router.push("/");
};

// 上一页
const goBack = () => {
  if (steps.value <= 1) {
    return false;
  } else {
    if (steps.value == 4) {
      ElMessageBox.confirm(
        "返回上一页将不会保存当前已上传的图片，确认继续操作吗?",
        "提示",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          steps.value--;
          if (reData.value.id || route.query.id) {
            getBussinessPersonInfoCheck();
            editPriceStatus.value = false;
          }
        })
        .catch(() => {
          console.log("取消返回");
        });
    } else {
      steps.value--;
      if (reData.value.id || route.query.id) {
        getBussinessPersonInfoCheck();
      }
    }
  }
};

const stepOneref = ref(null);
const stepTworef = ref(null);
const stepThreeref = ref(null);
const stepFourref = ref(null);

// 计时器
const count = ref(5);
const timeCount = () => {
  if (count.value === 0) {
    clearInterval(timeCount);
    router.push("/applyList");
    return false;
  } else {
    count.value--;
  }
};

const partnerPriceChange = (e) => {
  let price = e.target.value;
  if (price > 33) {
    ElMessage.error("贷款金额不能大于33万元，请检查");
  }
};

const editPriceFun = async () => {
  let flag = stepThreeref?.value?.formLabelAlign.every((v) => {
    if (amountRaiseStatus.value == '0') {
      return v.partnerLoanAmount <= 30;
    } else if (amountRaiseStatus.value == '1') {
      return v.partnerLoanAmount <= 33;
    }
  })
  if (amountRaiseStatus.value == '0' &&
    (stepOneref?.value?.formLabelAlign?.loanAmount > 30 || !flag)) {
    ElMessage.error('贷款金额不能大于30万元，请检查');
    return;
  }
  if (amountRaiseStatus.value == '1' &&
    (stepOneref?.value?.formLabelAlign?.loanAmount > 33 || !flag)) {
    ElMessage.error('贷款金额不能大于33万元，请检查');
    return;
  }
  editPriceStatus.value = true;
  visible_priceEdit.value = false;
  stagingType
  staging(stagingType.value);
};

const editPriceCancel = () => {
  let DETAIL_INFO = JSON.parse(localStorage.getItem('DETAIL_INFO'));
  stepOneref.value.formLabelAlign.loanAmount = DETAIL_INFO.loanAmount;
  stepThreeref.value.formLabelAlign.map((v, i) => {
    stepThreeref.value.formLabelAlign[i].partnerLoanAmount = DETAIL_INFO.partnerDtos[i].partnerLoanAmount
  })
  visible_priceEdit.value = false;
  editPriceStatus.value = false;
}

const fullDate = ref("");
const getDate = () => {
  let timer = new Date();
  const year = timer.getFullYear();
  const month =
    timer.getMonth() + 1 > 10
      ? timer.getMonth() + 1
      : "0" + parseInt(timer.getMonth() + 1); // 由于月份从0开始，因此需加1
  const day = timer.getDate() > 10 ? timer.getDate() : "0" + timer.getDate();
  fullDate.value = `${year}${month}${day}`;
};

// 检测身份证已经在3年内注册过了
const noPassIdNumber = ref(true);
const noPassMsg = ref("true");
const checkoutIdInThY = async () => {
  await getIdentityDuplicationInThreee(idNumber.value).then((res) => {
    if (res.code == 500) {
      noPassMsg.value = res.msg;
      noPassIdNumber.value = false;
      ElMessage.error(res.msg);
      return false;
    } else if (res.code == 200) {
    } else {
    }
  });
};

const disBtn = ref(0);
// 暂存
const temporaryStorageData = async (t) => {
  idNumber.value = stepOneref?.value?.formLabelAlign?.idNumber;
  let timer = setInterval(() => {
    disBtn.value++;
    if (disBtn.value > 2) {
      clearInterval(timer);
      disBtn.value = 0;
    }
  }, 1000);
  if (steps.value == '1') {
    checkoutIdInThY();
  }

  if (!noPassIdNumber.value) {
    ElMessage.error(noPassMsg.value);
    return false;
  };

  stepOneref?.value?.ruleFormRef?.validate((valid) => {
    if (valid) {
      staging(t);
    } else {
      ElMessage.error('表单验证不通过，请检查');
      steps.value = 1;
    }
  });
};

const checkIdNumberIsUsed = (id, callback) => {
  getIdentityDuplicationInThreee(id).then((res) => {
    if (res.code != 200) {
      callback(false, res.msg);
      return false;
    } else {
      callback(true, res.msg);
      return true;
    }
  });
};

const stagingType = ref("");
const staging = async (t) => {
  stagingType.value = t;
  let stepList = stepTworef?.value?.list;
  let stepThree = stepThreeref?.value?.formLabelAlign;

  let validateTwo = stepList.some(
    (item) => !!item.employeeIdNumber || !!item.employeeName || !!item.idType
  );
  if (steps.value == 2) {
    if (validateTwo) {
      let theSecond = stepList.every(
        (item) =>
          !!item.employeeIdNumber && !!item.employeeName && !!item.idType
      );
      if (!theSecond) {
        ElMessage.error("请完善员工信息");
        return false;
      }
    }

    let reg = /^([a-zA-Z0-9-]{1,18})$/;
    for (let i = 0; i < stepList.length; i++) {
      if (stepList[i].employeeIdNumber == idNumber.value) {
        ElMessage.error("员工身份证不能与主体身份证相同");
        return false;
      }
      checkIdNumberIsUsed(stepList[i].employeeIdNumber, (code) => {
        console.log(code);
        if (!code) {
          ElMessage.error(res.msg);
          return false;
        }
      });
      if (!reg.test(stepList[i].employeeIdNumber)) {
        ElMessage.error("身份证号码输入有误，请检查");
        return false;
      }
    }
  }
  let validateThree = stepThree.some(
    (item) =>
      !!item.partnerName ||
      !!item.idNumber ||
      !!item.partnerPhoneNumber ||
      !!item.partnerCategory ||
      !!item.partnerAddress ||
      !!item.idType ||
      !!item.partnerLoanAmount ||
      !!item.partnerAddress ||
      !!item.partnerLoanTerm
  );
  if (steps.value == 3) {
    if (!validateThree && stepOneref?.value?.formLabelAlign.loanAmount > 30) {
      ElMessage.warning("未添加合伙人时主体人贷款金额最高30万元，已修改为30万元");
      stepOneref.value.formLabelAlign.loanAmount = 30;
    };
    if (validateThree) {
      let theThird = stepThree.every(
        (item) =>
          !!item.partnerName &&
          !!item.idNumber &&
          !!item.partnerPhoneNumber &&
          !!item.partnerCategory &&
          !!item.idType &&
          !!item.partnerLoanAmount &&
          !!item.partnerAddress &&
          !!item.partnerLoanTerm
      );
      if (!theThird) {
        ElMessage.error("请完善合伙人信息");
        return false;
      }
    }
    let idReg = /^([a-zA-Z0-9-]{1,18})$/;
    let phoneReg = /^1\d{10}$/;
    for (let i = 0; i < stepThree.length; i++) {
      if (!idReg.test(stepThree[i].idNumber) && !!stepThree[i].idNumber) {
        ElMessage.error("身份证号码输入有误，请检查");
        return false;
      }

      if (idNumber.value == stepThree[i].idNumber) {
        ElMessage.error("合伙人身份证不能与主体身份证相同，请检查");
        return false;
      }

      for (let j = 0; j < stepThree.length; j++) {
        if (stepThree[j].idNumber == stepThree[i].idNumber && j != i) {
          ElMessage.error("已有相同的合伙人身份证号，请检查");
          return false;
        }
      }
      let partnerLoanAmount = Number(stepThree[i].partnerLoanAmount);
      if (partnerLoanAmount > 30 && !editPriceStatus.value && amountRaiseStatus.value == '0') {
        ElMessage.error("申请金额不能大于30万，请检查");
        return false;
      } else if (partnerLoanAmount > 33 && editPriceStatus.value && amountRaiseStatus.value == '1') {
        ElMessage.error("申请金额不能大于33万，请检查");
        return false;
      }

      if (
        !phoneReg.test(stepThree[i].partnerPhoneNumber) &&
        !!stepThree[i].partnerPhoneNumber
      ) {
        ElMessage.error("手机号格式有误，请检查");
        return false;
      }
      if (!!stepThree[i].partnerLoanTerm) {
        let partnerLoanTerm = Number(stepThree[i].partnerLoanTerm);
        let loanReg = /^\d{1,2}$/;
        if (!loanReg.test(partnerLoanTerm)) {
          ElMessage.error("贷款期限必须是1到2位数字");
          return false;
        }
        if (partnerLoanTerm > 36 || partnerLoanTerm < 1) {
          ElMessage.error("贷款期限在1-36个月之间");
          return false;
        }
      }
    }
    if (!editPriceStatus.value && stepThree.length > 0 && validateThree) {
      let params = {
        entrepreneurType: '0',
        ...stepOneref?.value?.formLabelAlign,
        operatingAddress:
          stepOneref?.value?.formLabelAlign.operatingAddress?.join(),
        permanentAddress:
          stepOneref?.value?.formLabelAlign.permanentAddress?.join(),
        residenceAddress:
          stepOneref?.value?.formLabelAlign.residenceAddress?.join(),

        employees: validateTwo ? stepList : null,
        partnerDtos: validateThree ? stepThree : null,
        quotaIncrease: '1',
        businessUrl: stepFourref.value.formLabelAlign.businessUrl,
        idNumberUrl: stepFourref.value.formLabelAlign.idNumberUrl,
        guaranteeCertificateUrl:
          stepFourref.value.formLabelAlign.guaranteeCertificateUrl,
        householdPhoto: stepFourref.value.formLabelAlign.householdPhoto,
        marriageCertificateUrl:
          stepFourref.value.formLabelAlign.marriageCertificateUrl,
        idNumberReverseUrl: stepFourref.value.formLabelAlign.idNumberReverseUrl,
        spouseIdNumberReverseUrl:
          stepFourref.value.formLabelAlign.spouseIdNumberReverseUrl,
        spouseIdNumberUrl: stepFourref.value.formLabelAlign.spouseIdNumberUrl,
        documentClass: JSON.parse(localStorage.getItem("DOCUMENT_CLASS")),

        id: reData?.value?.id || route.query.id,
        applicationDate:
          reData?.value?.applicationDate || detailInfo.value.applicationDate,
        registrationDateEntrepreneur:
          reData?.value?.registrationDateEntrepreneur ||
          detailInfo.value.registrationDateEntrepreneur,
      };
      if (params.map) {
        delete params.map;
      }
      await ElMessageBox.confirm(
        "合伙人贷款方式金额可以上浮10%，是否需要修改贷款金额?",
        "提示",
        {
          confirmButtonText: "金额上浮",
          cancelButtonText: "暂不需要",
          type: "warning",
          closeOnClickModal: false,
          showClose: false,
        }
      )
        .then(async (ret) => {
          if (ret == "confirm") {
            await temporaryStorage(params).then(res => {
              if (res.code == '200') {
                amountRaiseStatus.value = '1';
                visible_priceEdit.value = true;
                editPriceStatus.value = false;
                getBussinessPersonInfoCheck();
              } else {
                ElMessage.error(res.msg);
              }
            });
          }
        })
        .catch(async (err) => {
          if (err == "cancel") {
            params.quotaIncrease = '0';
            // await temporaryStorage(params).then(res => {
            //   if (res.code == '200') {
                amountRaiseStatus.value = '0';
                editPriceStatus.value = true;
                let flag = params.partnerDtos.every(v => v.partnerLoanAmount <= 30);
                if (!flag) {
                  ElMessage.error('贷款金额不能大于30万元，请修改');
                  visible_priceEdit.value = true;
                };
                getBussinessPersonInfoCheck();
            //   } else {
            //     ElMessage.error(res.msg);
            //   }
            // })
          }
        });
    }
    if (visible_priceEdit.value) return false;
  }
  let stepFour = stepFourref?.value?.formLabelAlign;
  if (steps.value == 4) {
    if (detailInfo.value.maritalStatus == "1") {
      if (
        !stepFour.businessUrl ||
        !stepFour.idNumberUrl ||
        !stepFour.guaranteeCertificateUrl ||
        !stepFour.householdPhoto ||
        !stepFour.marriageCertificateUrl ||
        !stepFour.idNumberReverseUrl ||
        !stepFour.spouseIdNumberReverseUrl ||
        !stepFour.spouseIdNumberUrl
      ) {
        ElMessage.error("有材料漏传，请检查");
        return false;
      }
    } else {
      if (
        !stepFour.businessUrl ||
        !stepFour.idNumberUrl ||
        !stepFour.guaranteeCertificateUrl ||
        !stepFour.householdPhoto ||
        !stepFour.idNumberReverseUrl
      ) {
        ElMessage.error("有材料漏传，请检查");
        return false;
      }
    };
    let documentClass = stepFourref.value.formLabelAlign.documentClass
    localStorage.setItem('DOCUMENT_CLASS', JSON.stringify(documentClass.join()));
  }
  let params = {
    entrepreneurType: '0',
    ...stepOneref?.value?.formLabelAlign,
    operatingAddress:
      stepOneref?.value?.formLabelAlign.operatingAddress?.join(),
    permanentAddress:
      stepOneref?.value?.formLabelAlign.permanentAddress?.join(),
    residenceAddress:
      stepOneref?.value?.formLabelAlign.residenceAddress?.join(),

    employees: validateTwo ? stepList : null,
    partnerDtos: validateThree ? stepThree : null,
    quotaIncrease: amountRaiseStatus.value,

    businessUrl: stepFourref.value.formLabelAlign.businessUrl,
    idNumberUrl: stepFourref.value.formLabelAlign.idNumberUrl,
    guaranteeCertificateUrl:
      stepFourref.value.formLabelAlign.guaranteeCertificateUrl,
    householdPhoto: stepFourref.value.formLabelAlign.householdPhoto,
    marriageCertificateUrl:
      stepFourref.value.formLabelAlign.marriageCertificateUrl,
    idNumberReverseUrl: stepFourref.value.formLabelAlign.idNumberReverseUrl,
    spouseIdNumberReverseUrl:
      stepFourref.value.formLabelAlign.spouseIdNumberReverseUrl,
    spouseIdNumberUrl: stepFourref.value.formLabelAlign.spouseIdNumberUrl,
    documentClass: JSON.parse(localStorage.getItem("DOCUMENT_CLASS")),

    id: reData?.value?.id || route.query.id,
    applicationDate:
      reData?.value?.applicationDate || detailInfo.value.applicationDate,
    registrationDateEntrepreneur:
      reData?.value?.registrationDateEntrepreneur ||
      detailInfo.value.registrationDateEntrepreneur,
  };

  if (steps.value == 1) {
    params = Object.assign(params, { currentSubmissionStatus: 1 });
    params.currentSubmissionStatus = 1;
  }

  if (steps.value == 2) {
    params = Object.assign(params, { currentSubmissionStatus: 2 });
    params.currentSubmissionStatus = 2;
  }

  if (steps.value == 3) {
    params = Object.assign(params, { currentSubmissionStatus: 3 });
    params.currentSubmissionStatus = 3;
  }

  if (steps.value == 4) {
    params = Object.assign(params, { currentSubmissionStatus: 4 });
    params.currentSubmissionStatus = 4;
  }

  if (params.map) {
    delete params.map;
  }
  try {
    const res = await temporaryStorage(params);
    if (res.code == 200) {
      editPriceStatus.value = false;
      ElMessage.success("暂存成功");
      localStorage.removeItem('DOCUMENT_CLASS');
      if (res.data?.id) {
        reData.value = res.data;
      }
      getBussinessPersonInfoCheck();
      if (stagingType.value == "save") {
        setTimeout(() => {
          router.push({
            path: "/applyList",
            query: {
              tabId: 0,
            },
          });
        }, 1000);
      } else {
        if (steps.value >= 4) {
          return false;
        } else {
          steps.value++;
        }
      }
    } else {
      ElMessage.error(res.msg);
    }
  } catch (e) {
    console.log(e, "this is error~");
  }
};

// 提交
const subCount = ref(0);
const submitData = async () => {
  let timer = setInterval(() => {
    subCount.value++;
    if (subCount.value > 2) {
      clearInterval(timer);
      subCount.value = 0;
    }
  }, 1000);

  // stepOneref?.value?.submitTemporaryStorage()
  let stepList = stepTworef?.value?.list;
  let stepThree = stepThreeref?.value?.formLabelAlign;

  let validateTwo = stepList.some(
    (item) =>
      item.employeeIdNumber != null ||
      item.employeeName != null ||
      item.idType != null
  );
  let validateThree = stepThree.some(
    (item) =>
      item.partnerName != null ||
      item.idNumber != null ||
      item.partnerPhoneNumber != null ||
      item.partnerCategory != null ||
      item.partnerAddress != null ||
      item.idType != null
  );

  let stepFour = stepFourref?.value?.formLabelAlign;
  if (steps.value == 4) {
    if (detailInfo.value.maritalStatus == "1") {
      if (
        !stepFour.businessUrl ||
        !stepFour.idNumberUrl ||
        !stepFour.guaranteeCertificateUrl ||
        !stepFour.householdPhoto ||
        !stepFour.marriageCertificateUrl ||
        !stepFour.idNumberReverseUrl ||
        !stepFour.spouseIdNumberReverseUrl ||
        !stepFour.spouseIdNumberUrl
      ) {
        ElMessage.error("有材料漏传，请检查");
        return false;
      }
    } else {
      if (
        !stepFour.businessUrl ||
        !stepFour.idNumberUrl ||
        !stepFour.guaranteeCertificateUrl ||
        !stepFour.householdPhoto ||
        !stepFour.idNumberReverseUrl
      ) {
        ElMessage.error("有材料漏传，请检查");
        return false;
      }
    };
    let documentClass = stepFourref.value.formLabelAlign.documentClass
    localStorage.setItem('DOCUMENT_CLASS', JSON.stringify(documentClass.join()));
  }
  if (!showLetter.value) {
      showLetter.value = true;
      return;
  } else {
      showLetter.value = false;
  }
  let params = {
    entrepreneurType: '0',
    ...stepOneref?.value?.formLabelAlign,
    // loanEntrepreneurType:validateThree ?1:0,
    entrepreneurType: validateThree > 0 ? 1 : 0,
    operatingAddress:
      stepOneref?.value?.formLabelAlign.operatingAddress?.join(),
    permanentAddress:
      stepOneref?.value?.formLabelAlign.permanentAddress?.join(),
    residenceAddress:
      stepOneref?.value?.formLabelAlign.residenceAddress?.join(),

    employees: validateTwo ? stepList : null,
    partnerDtos: validateThree ? stepThree : null,
    quotaIncrease: amountRaiseStatus.value,

    businessUrl: stepFourref.value.formLabelAlign.businessUrl,
    idNumberUrl: stepFourref.value.formLabelAlign.idNumberUrl,
    guaranteeCertificateUrl:
      stepFourref.value.formLabelAlign.guaranteeCertificateUrl,
    householdPhoto: stepFourref.value.formLabelAlign.householdPhoto,
    marriageCertificateUrl:
      stepFourref.value.formLabelAlign.marriageCertificateUrl,
    idNumberReverseUrl: stepFourref.value.formLabelAlign.idNumberReverseUrl,
    spouseIdNumberReverseUrl:
      stepFourref.value.formLabelAlign.spouseIdNumberReverseUrl,
    spouseIdNumberUrl: stepFourref.value.formLabelAlign.spouseIdNumberUrl,
    documentClass: JSON.parse(localStorage.getItem("DOCUMENT_CLASS")),
    currentSubmissionStatus: 4,

    id: reData?.value?.id || route.query.id,
    applicationDate:
      reData?.value?.applicationDate || detailInfo.value.applicationDate,
    registrationDateEntrepreneur:
      reData?.value?.registrationDateEntrepreneur ||
      detailInfo.value.registrationDateEntrepreneur,
  };

  if (params.map) {
    delete params.map;
  }

  try {
    const res = await submit(params);
    if (res.code === 200) {
      ElMessage.success("提交成功");
      let timer = setInterval(() => {
          submitCount.value--;
          if (submitCount.value == 0) {
              router.push('/applyList');
          }
          if (submitCount <= 0) {
              clearInterval(timer);
          }
      }, 1000);
      count.value = 0;
      localStorage.removeItem('DOCUMENT_CLASS');
    } else {
      ElMessage.error(res.msg);
    }
  } catch (e) {
    console.log(e, "this is error~");
  }
};

// 获取详细信息
const getBussinessPersonInfo = async () => {
  const id = route.query.id || reData.value.id;
  try {
    const res = await getEntrepreneurDoorDtoPerson(id);
    if (res.code === 200) {
      reData.value = res.data;
      detailInfo.value = res.data;
      localStorage.setItem('DETAIL_INFO', JSON.stringify(res.data));
      amountRaiseStatus.value = res.data.quotaIncrease || '0';
      if (route.query.id) {
        steps.value = res.data.currentSubmissionStatus;
      }
      // steps.value = 2
      let busId = res.data.residenceAddress?.split(",");
      let newNum = [];
      busId?.map((item) => {
        newNum.push(Number(item));
      });
      let newpId = new Proxy(newNum, {});

      let perId = res.data.permanentAddress?.split(",");
      let newNumPer = [];
      perId?.map((item) => {
        newNumPer.push(Number(item));
      });
      let newpIdPer = new Proxy(newNumPer, {});

      let operId = res.data.operatingAddress?.split(",");
      let onewNumPer = [];
      operId?.map((item) => {
        onewNumPer.push(Number(item));
      });
      let onewpIdPer = new Proxy(onewNumPer, {});

      Object.assign(stepOneref?.value?.formLabelAlign, res.data, {
        sex: res.data?.sex?.toString(),
        politicalOutlook: res.data.politicalOutlook?.toString(),
        residenceAddress: newpId,
        permanentAddress: newpIdPer,
        operatingAddress: onewpIdPer,
        firstEntrepreneurFlag: res.data.firstEntrepreneurFlag?.toString(),
        maritalStatus: res.data.maritalStatus?.toString(),
        spouseSex: res.data.spouseSex?.toString(),
        counterGuarantee: res.data.counterGuarantee?.toString(),
        entrepreneurRelation: res.data.entrepreneurRelation?.toString(),
        guaranteeWay: res.data.guaranteeWay?.toString(),
        entrepreneurCategory: res.data.entrepreneurCategory?.toString(),
        bankId: res.data.bankId?.toString(),
        loanEntrepreneurType: res.data.loanEntrepreneurType?.toString(),
      });

      stepTworef.value.list.length = 0;
      if (res.data.employees) {
        // stepTworef.value.list.push(...res.data.employees);
        res.data.employees.forEach(async (item, index) => {
          if (item.idNumberOpposite) {
            const response = await downloadByFileId(item.idNumberOpposite);
            item.idNumberOppositeRead = URL.createObjectURL(response);
          }
          if (item.idNumberUrl) {
            const response = await downloadByFileId(item.idNumberUrl);
            item.idNumberUrlRead = URL.createObjectURL(response);
          }
          stepTworef.value.list.push(...[item])
        });
      }

      stepThreeref.value.formLabelAlign.length = 0;
      if (res.data.partnerDtos) {
        stepThreeref.value.formLabelAlign.push(...res.data.partnerDtos);
      };
      if (!res.data.documentClass) {
        res.data.documentClass = [];
      } else {
        let data = res.data.documentClass;
        res.data.documentClass = data.split(',');
      }

      Object.assign(stepFourref.value.formLabelAlign, res.data);
    }
  } catch (e) {
    console.log(e, "this is error~");
  }
};

// 查询
const getBussinessPersonInfoCheck = async () => {
  const id = route.query.id || reData.value.id;
  try {
    const res = await getEntrepreneurDoorDtoPerson(id);
    if (res.code === 200) {
      reData.value = res.data;
      amountRaiseStatus.value = res.data.quotaIncrease || '0';
      detailInfo.value = res.data;
      localStorage.setItem('DETAIL_INFO', JSON.stringify(res.data));
      let busId = res.data.residenceAddress?.split(",");
      let newNum = [];
      busId?.map((item) => {
        newNum.push(Number(item));
      });
      let newpId = new Proxy(newNum, {});

      let perId = res.data.permanentAddress?.split(",");
      let newNumPer = [];
      perId?.map((item) => {
        newNumPer.push(Number(item));
      });
      let newpIdPer = new Proxy(newNumPer, {});

      let operId = res.data.operatingAddress?.split(",");
      let onewNumPer = [];
      operId?.map((item) => {
        onewNumPer.push(Number(item));
      });
      let onewpIdPer = new Proxy(onewNumPer, {});

      Object.assign(stepOneref?.value?.formLabelAlign, res.data, {
        sex: res.data?.sex?.toString(),
        politicalOutlook: res.data.politicalOutlook?.toString(),
        residenceAddress: newpId,
        permanentAddress: newpIdPer,
        operatingAddress: onewpIdPer,
        firstEntrepreneurFlag: res.data.firstEntrepreneurFlag?.toString(),
        maritalStatus: res.data.maritalStatus?.toString(),
        spouseSex: res.data.spouseSex?.toString(),
        counterGuarantee: res.data.counterGuarantee?.toString(),
        entrepreneurRelation: res.data.entrepreneurRelation?.toString(),
        guaranteeWay: res.data.guaranteeWay?.toString(),
        entrepreneurCategory: res.data.entrepreneurCategory?.toString(),
        guaranteeWay: res.data.guaranteeWay?.toString(),
        bankId: res.data.bankId?.toString(),
        loanEntrepreneurType: res.data.loanEntrepreneurType?.toString(),
      });

      stepTworef.value.list.length = 0;

      if (res.data.employees) {
        // stepTworef.value.list.push(...res.data.employees);
        res.data.employees.forEach(async (item, index) => {
          if (item.idNumberOpposite) {
            const response = await downloadByFileId(item.idNumberOpposite);
            item.idNumberOppositeRead = URL.createObjectURL(response);
          }
          if (item.idNumberUrl) {
            const response = await downloadByFileId(item.idNumberUrl);
            item.idNumberUrlRead = URL.createObjectURL(response);
          }
          stepTworef.value.list.push(...[item])
        });
      }
      // if (res.data.employees) {
      //   stepTworef.value.list.push(...res.data.employees);
      // }

      stepThreeref.value.formLabelAlign.length = 0;
      if (res.data.partnerDtos) {
        stepThreeref.value.formLabelAlign.push(...res.data.partnerDtos);
      }

      if (!res.data.documentClass) {
        res.data.documentClass = [];
      } else {
        let data = res.data.documentClass;
        res.data.documentClass = data.split(',');
      }

      Object.assign(stepFourref.value.formLabelAlign, res.data);
    }
  } catch (e) {
    console.log(e, "this is error~");
  }
};
// 设置当前高亮样式
let clsTwo = (e) => {
  if (e == 2) {
    return "active active_two";
  }
  if (e > 2) {
    return "done";
  }
};

let clsThree = (e) => {
  if (e < 3) {
    return "";
  }
  if (e == 3) {
    return "active active_two";
  }
  if (e > 3) {
    return "done";
  }
};

const contTest = ref(0);
const testCount = () => {
  let timer = setInterval(() => {
    contTest.value++;
    if (contTest.value > 3) {
      clearInterval(timer);
      contTest.value = 0;
    }
  }, 1000);
};

const busNext = () => {
    showLetter.value = false;
}

onMounted(() => {
  getDate();
  if (route.query.id) {
    setTimeout(() => {
      stepTworef.value.list.length = 0;
      // stepThreeref.value.formLabelAlign.length = 0
      getBussinessPersonInfo();
    }, 1000);
  }
  idNumber.value = stepOneref?.value?.formLabelAlign?.idNumber;
});

onUnmounted(() => {
  localStorage.removeItem('DETAIL_INFO');
})
</script>

<template>
  <div class="layout" v-show="!showLetter">
    <div class="banner">
      <img src="/src/assets/businessPersonInfo/img-banner.png" />
      <!-- <i>个人及合伙人创业担保贷款申请表</i> -->
    </div>

    <div class="all">
      <div class="position">
        您当前的位置：<label @click="router.push('/')">首页</label> /
        <i>个人及合伙人创业担保贷款申请表</i>
      </div>
      <div class="cont">
        <ul>
          <li :class="`one ${steps == 1 ? 'active ' : 'active_one done'}`">
            <label class="circle"><i>1</i></label>
            <span>创业人员基本信息</span>
            <span class="sub">请填写基本信息</span>
          </li>
          <li :class="`two ${clsTwo(steps)}`">
            <label class="circle"><i>2</i></label>
            <span>员工信息</span>
            <span class="sub">请填写员工信息</span>
          </li>
          <li :class="`two ${clsThree(steps)}`">
            <label class="circle"><i>3</i></label>
            <span>合伙人信息</span>
            <span class="sub">如有合伙人请提填写</span>
          </li>
          <li :class="`two ${steps == 4 ? ' active active_two' : ''}`">
            <label class="circle"><i>4</i></label>
            <span>相关证件材料</span>
            <span class="sub">请上传申请材料</span>
          </li>
        </ul>

        <template v-if="count != 0">
          <StepOone :amountRaiseStatus="amountRaiseStatus" v-show="steps == 1" ref="stepOneref"></StepOone>
          <StepTwo
            v-show="steps == 2"
            ref="stepTworef"
            :idNumber="idNumber"
          ></StepTwo>
          <StepThree
            v-show="steps == 3"
            ref="stepThreeref"
            :idNumber="idNumber"
            :amountRaiseStatus="amountRaiseStatus"
          ></StepThree>
          <StepFour
            v-show="steps == 4"
            ref="stepFourref"
            :detailInfo="detailInfo"
            :steps="steps"
          ></StepFour>
        </template>

        <template v-if="count == 0">
          <div class="submit-done-box">
            <img :src="Done" />
            <div>
              恭喜，贷款申请提交成功，我们将会在X个工作日内审核完成，请您及时关注我的申请页面
            </div>
            <div class="count">
              <i>{{ submitCount }}</i
              >秒后自动跳转到我的申请页面
            </div>

            <div class="btn-list">
              <span @click="router.push('/applyList')">进入我的申请</span
              ><span @click="goIndex">返回首页</span>
            </div>
          </div>
        </template>
      </div>

      <div class="bottom-box">
        <div class="bottom" v-if="count != 0">
          <div class="line"></div>

          <template v-if="steps == 1">
            <div class="btn-list">
              <span @click="goIndex" v-if="steps == 1">返回首页</span>
              <span @click="disBtn == 0 ? temporaryStorageData('save') : ''"
                >暂存</span
              >
              <span @click="disBtn == 0 ? temporaryStorageData('next') : ''"
                >下一页</span
              >
            </div>
          </template>

          <template v-else>
            <div class="btn-list">
              <span @click="goBack" v-if="steps != 1">上一页</span>
              <span @click="disBtn == 0 ? temporaryStorageData('save') : ''"
                >暂存</span
              >
              <span
                @click="disBtn == 0 ? temporaryStorageData('next') : ''"
                v-if="steps != 4"
                >下一页</span
              >
              <span @click="subCount == 0 ? submitData() : ''" v-if="steps == 4"
                >提交贷款申请</span
              >
            </div>
          </template>

          <!-- <div @click="contTest==0?testCount():''">计时器{{ contTest }}</div> -->
        </div>
      </div>
    </div>
  </div>
  <Letter @busNext="busNext" @busSubmit="submitData" v-if="showLetter" :id="reData.id"></Letter>

  <div class="popup_edit" v-if="visible_priceEdit" @mousewheel.stop.prevent="">
    <div class="mask" @click="visible_priceEdit = false"></div>
    <div class="content">
      <h3 class="title">合伙人贷款金额修改</h3>
      <div class="list">
        <div class="items">
          <el-form-item label="主体人" style="width: 12rem; margin-right: 2rem">
            <el-input
              v-model="stepOneref.formLabelAlign.name"
              disabled
            />
          </el-form-item>
          <el-form-item
            label="贷款金额"
            style="width: 14rem; display: flex; align-items: center"
          >
            <el-input
              v-model="stepOneref.formLabelAlign.loanAmount"
              placeholder="请输入贷款金额"
              @blur="partnerPriceChange"
            >
              <template #append>万元</template>
            </el-input>
          </el-form-item>
        </div>
        <div
          class="items"
          v-for="(item, index) in stepThreeref.formLabelAlign"
          :key="index"
        >
          <el-form-item label="贷款人" style="width: 12rem; margin-right: 2rem">
            <el-input
              v-model="stepThreeref.formLabelAlign[index].partnerName"
              disabled
            />
          </el-form-item>
          <el-form-item
            label="贷款金额"
            style="width: 14rem; display: flex; align-items: center"
          >
            <el-input
              v-model="stepThreeref.formLabelAlign[index].partnerLoanAmount"
              placeholder="请输入贷款金额"
              @blur="partnerPriceChange"
            >
              <template #append>万元</template>
            </el-input>
          </el-form-item>
        </div>
      </div>
      <div class="popup-btns">
        <el-button
          type="default"
          size="large"
          @click="editPriceCancel"
          >取消</el-button
        >
        <el-button type="warning" size="large" @click="editPriceFun"
          >确认</el-button
        >
      </div>
    </div>
  </div>
</template>

<style src="./index.css" scoped></style>
<style lang="scss" scoped>
.popup_edit {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  animation: show 0.2s linear;
  .mask {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
  }
  .content {
    padding: 0 1rem 1rem 2rem;
    position: absolute;
    width: 33%;
    max-height: 30rem;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 12px;
    h3 {
      text-align: center;
    }
    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-height: 20rem;
      overflow: hidden;
      overflow-y: auto;
    }
    .items {
      display: flex;
      align-items: center;
    }
    .popup-btns {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      :deep(.el-button) {
        width: 8rem;
      }
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
